import React, { Suspense, useEffect, useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useParams } from 'react-router-dom';

import { useLanguage } from '../../../utils/context/lang';
import { i18n as chatI18n } from '../../i18n/chat';

import { FIELD_COMPONENTS } from './field-mapping';

const DynamicQueryForm = ({ fields, values, totalCount, onChange, onSearch, onToggle }) => {
  const { workflowId } = useParams();
  const [ isExpanded, setIsExpanded ] = useState(true);
  const [ localValues, setLocalValues ] = useState(values);
  const { lang } = useLanguage();
  
  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(localValues);
    setIsExpanded(false);
    onToggle?.(false);
  };

  const handleFieldChange = (name, value) => {
    setLocalValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    setLocalValues(values);
  }, [ values ]);

  useEffect(() => {
    setIsExpanded(true);
  }, [ workflowId ]);

  // Function to get active filter count and summary
  const getActiveFilters = () => {
    const activeFilters = Object.entries(localValues)
      .filter(([ _, value ]) => {
        if (Array.isArray(value)) return value.length > 0;
        if (typeof value === 'object' && value !== null && 'data' in value) {
          return value.data.length > 0;
        }
        return value !== undefined && value !== null && value !== '';
      });
    if (activeFilters.length === 0) return null;

    // Get field display names from the fields prop
    const fieldMap = Object.fromEntries(fields.map(f => [ f.name, f.display ]));
    
    // Get first three filters for preview
    const preview = activeFilters
      .slice(0, 3)
      .map(([ key, value ]) => {
        const displayName = fieldMap[key];
        const displayValue = Array.isArray(value) 
          ? `${value.length} ${chatI18n.SELECTED_COUNT[lang]}` 
          : typeof value === 'object' && value !== null && 'data' in value ? `${value.data.length} ${chatI18n.SELECTED_COUNT[lang]}` : `${value}` ;
        return `${displayName}: ${displayValue}`;
      })
      .join(' • ');

    return {
      count: activeFilters.length,
      preview: preview + (activeFilters.length > 3 ? ` +${activeFilters.length - 3} ${chatI18n.MORE[lang]}` : '')
    };
  };

  const activeFilters = getActiveFilters();

  return (
    <form onSubmit={handleSearch} className="relative">
      {/* Filter Toggle Button */}
      <button
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        className={`w-full flex items-center gap-3 px-6 py-3.5 text-sm 
          hover:bg-base-200/30 transition-colors duration-200
          ${isExpanded ? 'border-b border-base-200' : ''}`}
      >
        <div className="flex items-center gap-3 min-w-fit">
          <MagnifyingGlassIcon className="w-4 h-4 text-base-content/50" />
          <span className="text-base-content/70 font-medium">
            {chatI18n.SEARCH_AND_FILTER[lang]}
          </span>
          <span className="text-base-content/70 font-medium">
            {chatI18n.TOTAL_RESULTS_COUNT[lang].replace('{count}', totalCount)}
          </span>
        </div>

        {activeFilters && (
          <div className="flex items-center gap-2 ml-4 max-w-[60%] overflow-hidden">
            <div className="h-4 w-px bg-base-300" />
            <span className="text-xs text-base-content/50 truncate">
              {activeFilters.preview}
            </span>
          </div>
        )}

        <div className="flex-1" />
        {isExpanded ? (
          <ChevronUpIcon className="w-4 h-4 text-base-content/50" />
        ) : (
          <ChevronDownIcon className="w-4 h-4 text-base-content/50" />
        )}
      </button>

      {/* Filter Fields Container */}
      <div className={`overflow-hidden transition-all duration-200 ease-in-out
        ${isExpanded ? 'max-h-[60vh] opacity-100' : 'max-h-0 opacity-0'}`}
      >
        {/* Scrollable content area */}
        <div className="max-h-[calc(60vh-64px)] overflow-y-auto">
          <div className="p-6 space-y-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-4 max-w-5xl mx-auto">
              {fields.map((field) => {
                const FieldComponent = FIELD_COMPONENTS[field.component];
                
                if (!FieldComponent) {
                  console.warn(`No component found for ${field.component}`);
                  return null;
                }

                return (
                  <div key={field.name} className="space-y-1.5">
                    <label className="text-sm font-medium text-base-content/70">
                      {field.display}
                    </label>
                    <Suspense fallback={
                      <div className="h-10 animate-pulse bg-base-200/50 rounded-lg" />
                    }>
                      <FieldComponent
                        value={localValues[field.name]}
                        onChange={(value) => handleFieldChange(field.name, value)}
                        placeholder={`${chatI18n.FILTER_BY[lang]} ${field.display.toLowerCase()}`}
                        className="w-full bg-base-100 border border-base-200 rounded-lg 
                          px-3 py-2 text-sm placeholder:text-base-content/30
                          focus:ring-1 focus:ring-primary/20 focus:border-primary 
                          transition-shadow duration-200"
                      />
                    </Suspense>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Fixed position action buttons */}
        <div className="sticky bottom-0 bg-base-100 border-t border-base-200">
          <div className="flex justify-end gap-3 p-4 max-w-4xl mx-auto">
            <button
              type="button"
              onClick={() => {
                setLocalValues({});
                onChange({});
              }}
              className="btn btn-ghost btn-sm text-base-content/70 hover:text-base-content"
            >
              {chatI18n.CLEAR_ALL[lang]}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-sm gap-2"
            >
              <MagnifyingGlassIcon className="w-4 h-4" />
              {chatI18n.SEARCH[lang]}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DynamicQueryForm;