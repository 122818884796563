/* eslint-disable no-useless-escape */
import { processMarkdownToCleanHTML } from '../../utils/markdown-to-html';

const DisplayFormattedText = ({ text }) => {
  // upgrade to use markdown-it for render and DOMPurify for security (xss)
  const cleanHTML = processMarkdownToCleanHTML(text);

  // const cleanHTML = DOMPurify.sanitize(md.render(text));
  // const cleanHTML = DOMPurify.sanitize(
  //   md.render(
  //     '# Math Rulez! \n  $\\sqrt{3x-1}+(1+x)^2$ $WACC = \\frac{E}{V} \\times Re + \\frac{D}{V} \\times Rd \\times (1 - Tc)$'
  //   )
  // );
  // const cleanHTML = DOMPurify.sanitize(md.render('$$WACC = \\frac{E}{V} \\times Re + \\frac{D}{V} \\times Rd \\times (1 - Tc)$$'))
  // const cleanHTML = DOMPurify.sanitize(md.render(`Certainly! Let's consider a very simple and classic formula, the equation of a line in slope-intercept form:

  // \[ y = mx + b \]

  // Where:
  // - \(y\) is the dependent variable (the value of \(y\) depends on the value of \(x\)),
  // - \(x\) is the independent variable,
  // - \(m\) is the slope of the line (it represents the rate of change of \(y\) with respect to \(x\)), and
  // - \(b\) is the y-intercept of the line (it is the value of \(y\) when \(x = 0\)).
  // `))
  return (
    <div
      className="prose prose-base max-w-none
        prose-h1:text-xl prose-h1:mt-4 prose-h1:mb-0 prose-h1:font-bold prose-h1:no-underline
        prose-h2:text-lg prose-h2:mt-2 prose-h2:mb-0 prose-h2:mt-4 prose-h2:no-underline
        prose-h3:text-base prose-h3:mt-2 prose-h3:mb-0 prose-h3:mt-3 prose-h3:no-underline
        prose-h4:text-base prose-h4:mt-2 prose-h4:mb-0 prose-h4:mt-0 prose-h4:no-underline
        prose-p:text-primary-content prose-p:my-0 prose-p:no-underline
        prose-li:text-primary-content prose-li:mt-2 prose-li:leading-relaxed prose-li:text-sm
        prose-ul:pl-5 prose-ul:my-2 prose-ol:pl-5
        prose-li:marker:text-primary-content
        prose-pre:bg-base-300 prose-pre:p-4 prose-pre:rounded-lg
        prose-code:text-secondary-content prose-code:bg-base-300 prose-code:px-1 prose-code:rounded
        hover:prose-a:text-primary-content hover:prose-a:underline prose-a:transition-colors
        [&_.reference-link]:text-primary 
        hover:[&_.reference-link]:text-primary-focus 
        [&_.reference-link]:cursor-pointer 
        [&_.reference-link]:border-b 
        [&_.reference-link]:border-dotted
        prose-table:border-collapse prose-table:w-full
        prose-td:p-2 prose-th:p-2
        prose-thead:bg-base-300 prose-p:text-sm"
      dangerouslySetInnerHTML={{ __html: cleanHTML }}
    />
  );
};

export default DisplayFormattedText;
