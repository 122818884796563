import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';


import { Bars4Icon, TableCellsIcon } from '@heroicons/react/24/outline';
import { AgGridReact } from 'ag-grid-react';
import { motion } from 'framer-motion';
import Masonry from 'react-masonry-css';
import { useLoaderData, useLocation, useParams } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { getConferenceDetail, getConferenceSettings, getThreadTasks } from '../api';
import ChatPanel from '../Common/ChatPanal';
import EmptyState from '../Common/EmptyState';
import { i18n as chatI18n } from '../Common/i18n/chat';
import PaperCard from '../Common/Medical/Card/PaperCard';
import DynamicQueryForm from '../Common/Medical/Form/DynamicQueryForm';
import { CONFERENCE_COLUMN } from '../Common/Medical/Table/columns/conference';
import { useAlert } from '../utils/context/alert';
import { useHistory } from '../utils/context/history';
import { useLanguage } from '../utils/context/lang';
import { useLayout } from '../utils/context/layout';

const PaperCardSkeleton = () => (
  <div className="bg-base-100 rounded-lg overflow-hidden border border-base-200 animate-pulse">
    <div className="p-4 border-b border-base-200">
      <div className="flex justify-between mb-4">
        <div className="h-4 w-16 bg-base-200 rounded"></div>
        <div className="h-4 w-24 bg-base-200 rounded"></div>
      </div>
      <div className="h-6 w-3/4 bg-base-200 rounded mb-3"></div>
      <div className="h-4 w-1/2 bg-base-200 rounded"></div>
    </div>
    <div className="p-4 space-y-4">
      <div className="space-y-2">
        <div className="h-4 w-full bg-base-200 rounded"></div>
        <div className="h-4 w-5/6 bg-base-200 rounded"></div>
      </div>
      <div className="flex gap-2">
        <div className="h-6 w-20 bg-base-200 rounded"></div>
        <div className="h-6 w-20 bg-base-200 rounded"></div>
      </div>
    </div>
  </div>
);

const LoadingGrid = ({ count = 6 }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
    {[ ...Array(count) ].map((_, i) => (
      <PaperCardSkeleton key={i} />
    ))}
  </div>
);

const breakpointColumns = {
  default: 2,
  1400: 2,
  1024: 2,
  768: 1,
};

const MAX_SELECTED_ASSETS = 5;

const formatQueryValues = (queryValues) => {
  const resultValues = JSON.parse(JSON.stringify(queryValues));
  Object.keys(resultValues).forEach((key) => {
    const item = resultValues[key];
    const data = item?.data;
    const formatArray = (array) => {
      return array.map((subItem) => {
        return subItem[subItem.length - 1]
      })
    }
    if (Array.isArray(item) && Array.isArray(item[0])) {
      resultValues[key] = formatArray(item);
    } else if (Array.isArray(data) && Array.isArray(data[0])) {
      resultValues[key].data = formatArray(data);
    }
  });
  return resultValues;
}

export function ConferenceDetail() {
  const { conferenceId: workflowId, threadId: urlThreadId } = useParams();
  const location = useLocation();
  const { workflowSettings, workflowData: initialWorkflowData, threadData, initialQueryValues, queryValuesForSearch } = useLoaderData();
  console.log('Initial data:', {
    initialWorkflowData,
    isArray: Array.isArray(initialWorkflowData),
    firstItem: initialWorkflowData?.[0]
  });
  const [ isPanelOpen, setIsPanelOpen ] = useState(true);
  const [ workflowData, setWorkflowData ] = useState(initialWorkflowData || { data: [], count: 0, page: 0, total_pages: 0 });
  const [ isLoading, setIsLoading ] = useState(false);
  const [ hasMore, setHasMore ] = useState(true);
  const [ page, setPage ] = useState(1);
  const [ filters, setFilters ] = useState(initialQueryValues || {});
  const [ activeThreadId, setActiveThreadId ] = useState(urlThreadId);
  const observerTarget = useRef(null);
  const { lang } = useLanguage();
  const showAlert = useAlert();
  const [ queryValues, setQueryValues ] = useState({});
  const [ viewMode, setViewMode ] = useState('grid'); // 'grid' or 'table'
  const gridRef = useRef();
  const prevWorkflowIdRef = useRef();
  const gridApiRef = useRef(null);
  const { updateHistory } = useHistory();

  // Add this effect to scroll to top when workflowId changes
  useEffect(() => {
    window.scrollTo(0, 0);
    // Clear the data immediately when workflowId changes
    setWorkflowData([]);
    setIsLoading(true);
  }, [ workflowId ]);

  // Handle initialization of new data
  useEffect(() => {
    console.log('re inited.......')
    
    setWorkflowData(initialWorkflowData || []);
    setIsLoading(false);
    setHasMore(true);
    setPage(1);
    setQueryValues(queryValuesForSearch || {});

    // clear states when workflowId changes
    const previousWorkflowId = prevWorkflowIdRef.current;
    prevWorkflowIdRef.current = workflowId;
    if (previousWorkflowId && (previousWorkflowId !== workflowId)) {
      // Clear query values
      setFilters({});
      // Clear selected assets
      setSelectedAssets([]);
      // Reset chat-related states
      setActiveThreadId(null);
      // Reset panel state if needed
      setIsPanelOpen(true);
    }
    
    // Set query values from loader if they exist
    if (initialQueryValues && Object.keys(initialQueryValues).length > 0) {
      console.log('Setting query values from loader:', initialQueryValues);
      setFilters(initialQueryValues);
    }
  }, [ workflowId, initialWorkflowData, initialQueryValues ]);

  const [ selectedAssets, setSelectedAssets ] = useState([]);
  const containerRef = useRef(null);
  const { collapseSidebar } = useLayout();

  const rowSelection = useMemo(() => {
    return { mode: 'multiRow' };
  }, [  ]);
  // NOTE: SEEMS IMPORTANT. Memoize initialConversations to maintain stable reference, otherwise it will trigger the chat panel to reset.
  // const initialConversations = useMemo(() => 
  //   threadData?.initialData?.results || [], 
  // [ threadData?.initialData?.results ]
  // );

  // Handle infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          loadMoreData();
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current && hasMore && !isLoading && viewMode === 'grid') {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [ hasMore, isLoading, page, queryValues, viewMode ]);

  // Load more data
  const loadMoreData = async () => {
    if (isLoading || !hasMore) return;
    
    setIsLoading(true);
    try {
      const newData = await getConferenceDetail(workflowId, queryValues, page + 1);
      if (newData?.data?.length === 0) {
        setHasMore(false);
      } else {
        const data = newData?.data || [];
        setWorkflowData(prev => Array.isArray(prev?.data ?? []) ? { data: [ ...prev.data, ...data ], count: newData.count, page: newData.page, total_pages: newData.total_pages } : newData);
        setPage(prev => prev + 1);
      }
    } catch (error) {
      setHasMore(false);
      showAlert('error', 'Failed to load more data');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle query submission
  const handleQuerySubmit = async (newQueryValues) => {
    const formattedQueryValues = formatQueryValues(newQueryValues);
    setIsLoading(true);
    setQueryValues(formattedQueryValues);
    setSelectedAssets([]);
    try {
      const newData = await getConferenceDetail(workflowId, formattedQueryValues, 1);
      setWorkflowData(newData);
      setPage(1);
      if (newData?.data.length > 0) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      showAlert('error', 'Failed to query papers');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle thread creation
  const handleThreadCreated = (newThreadId) => {
    // NOTE: we should not set activeThreadId here, because it will trigger the chat panel to reset.
    // setActiveThreadId(newThreadId);
    window.history.replaceState(
      null, 
      '', 
      `/conference/${workflowId}/chat/${newThreadId}`
    );
    setActiveThreadId(newThreadId);
    updateHistory();
  };

  // Sync with URL changes
  useEffect(() => {
    setActiveThreadId(urlThreadId);
  }, [ urlThreadId ]);

  const toggleAssetSelect = (asset) => {
    setSelectedAssets(prev => {
      const isSelected = prev.some(p => p.id === asset.id);
      if (isSelected) {
        // Remove the asset if it's already selected
        return prev.filter(p => p.id !== asset.id);
      } else {
        // Add the asset if we're under the limit
        if (prev.length >= MAX_SELECTED_ASSETS) {
          showAlert('error', 'You can only select up to 5');
          return prev;
        }
        return [ ...prev, asset ];
      }
    });
  };
  // Handle responsive sidebar collapse
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        if (containerWidth < 400) {
          collapseSidebar();
        }
      }
    };

    // Initial check
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Get column definitions based on workflow type
  const columnDefs = useMemo(() => {
    return CONFERENCE_COLUMN;
  }, [ ]);

  // AG Grid default configuration
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 30,
  }), []);

  // Add effect to sync grid selection with selectedAssets
  useEffect(() => {
    if (viewMode === 'table' && gridApiRef.current) {
      const api = gridApiRef.current;
      
      // Get all row nodes
      api.forEachNode(node => {
        // Check if this row should be selected
        const shouldBeSelected = selectedAssets.some(asset => asset.id === node.data.id);
        // Only update if the selection state is different
        if (node.isSelected() !== shouldBeSelected) {
          node.setSelected(shouldBeSelected);
        }
      });
    }
  }, [ selectedAssets, viewMode ]);
  // Update the refreshIndexColumn function to also sync selection
  const refreshIndexColumn = () => {
    if (gridApiRef.current) {
      // First refresh the index column
      gridApiRef.current.refreshCells({
        columns: [ 'rowIndex' ],
        force: true
      });

      // Then re-sync the selection state
      gridApiRef.current.forEachNode(node => {
        const shouldBeSelected = selectedAssets.some(asset => asset.id === node.data.id);
        if (node.isSelected() !== shouldBeSelected) {
          node.setSelected(shouldBeSelected);
        }
      });
    }
  };
  const handleSelectAll = () => {
    const maxItems = MAX_SELECTED_ASSETS;
    
    if (viewMode === 'table' && gridApiRef.current) {
      // Table view selection logic
      const allVisibleRows = [];
      gridApiRef.current.forEachNodeAfterFilterAndSort((node) => {
        allVisibleRows.push(node);
      });

      // Take only the first maxItems
      const rowsToSelect = allVisibleRows.slice(0, maxItems);
      
      // Deselect all first
      gridApiRef.current.deselectAll();
      
      // Select the first maxItems rows
      rowsToSelect.forEach(node => node.setSelected(true));
      
      // Update selectedAssets state
      setSelectedAssets(rowsToSelect.map(node => node.data));
    } else {
      // Grid view selection logic
      const itemsToSelect = workflowData?.data?.slice(0, maxItems);
      setSelectedAssets(itemsToSelect);
    }
    
    // Show alert if there are more items than the limit
    if (workflowData?.data?.length > maxItems) {
      showAlert('error', `${chatI18n.MAX_SELECT_ERROR[lang]} ${maxItems} ${chatI18n.ITEMS[lang]}`);
    }
  };

  const handleDownload = () => {
    let rowCount = 0;
    if (gridApiRef.current) {
      // Get all visible rows in their current sort/filter order
      const allVisibleRows = [];
      gridApiRef.current.forEachNodeAfterFilterAndSort((node) => {
        allVisibleRows.push(node.data);
      });
      
      // Export using the current API
      gridApiRef.current.exportDataAsCsv({
        onlySelected: false,
        shouldRowBeSkipped: (params) => {
          // Skip rows after 50 have been included
          // NOTE: we are limiting the number of rows to 50 to protect the server from being overwhelmed
          if (rowCount >= 50) {
            return true;
          }
          rowCount++;
          return false;
        },
        processCellCallback: (params) => {
          return params.value;
        },
        getCustomContentBelowRow: () => null,
      });

      // if (allVisibleRows.length > 50) {
      //   showAlert('info', lang === 'en' ? 'Download limited to first 50 items' : '下载限制为前50条数据');
      // }
    }
  };

  return (
    <div ref={containerRef} className="relative min-h-screen bg-base-100">
      {/* Sticky Search Section */}
      <div className="sticky top-0 z-30 bg-base-100/80 backdrop-blur-sm border-b border-base-200">
        <div className={`transition-all duration-300 ease-in-out mx-auto
          ${isPanelOpen ? 'lg:mr-[666px]' : 'lg:mr-0'}`}>
          <div className="max-w-7xl mx-auto px-4 md:px-8 py-4">
            <div className="flex flex-wrap items-center justify-between gap-4 mb-6">
              {/* Title */}
              <div className="flex-1 min-w-[200px]">
                <h1 className="text-2xl font-semibold tracking-tight text-base-content">
                  {workflowSettings.name}
                </h1>
                <p className="mt-1 text-sm text-base-content/60">
                  {workflowSettings.description}
                </p>
              </div>

              {/* View Toggle */}
              <div className="flex items-center gap-2">
                {/* Select/Deselect All Buttons */}
                <div className="flex items-center bg-base-100/50 backdrop-blur-sm 
                  border border-base-200 rounded-full p-1 shadow-sm mr-2">
                  <button
                    onClick={handleSelectAll}
                    className="px-3 py-1.5 rounded-full text-sm font-medium text-base-content/60 
                      hover:text-base-content hover:bg-base-200/50 transition-all duration-200"
                  >
                    {chatI18n.SELECT_ALL[lang]}
                  </button>
                  <button
                    onClick={() => {
                      setSelectedAssets([]);
                      if (viewMode === 'table' && gridApiRef.current) {
                        gridApiRef.current.deselectAll();
                      }
                    }}
                    className="px-3 py-1.5 rounded-full text-sm font-medium text-base-content/60 
                      hover:text-base-content hover:bg-base-200/50 transition-all duration-200"
                  >
                    {chatI18n.DESELECT_ALL[lang]}
                  </button>
                </div>

                <div className="flex items-center bg-base-100/50 backdrop-blur-sm 
                  border border-base-200 rounded-full p-1 shadow-sm">
                  <button
                    onClick={() => setViewMode('grid')}
                    className={`flex items-center px-3 py-1.5 rounded-full text-sm font-medium 
                      transition-all duration-200 ${
    viewMode === 'grid'
      ? 'bg-primary text-primary-content shadow-sm'
      : 'text-base-content/60 hover:text-base-content hover:bg-base-200/50'
    }`}
                  >
                    <Bars4Icon className="w-4 h-4 mr-1.5" />
                    {chatI18n.GRID[lang]}
                  </button>
                  <button
                    onClick={() => setViewMode('table')}
                    className={`flex items-center px-3 py-1.5 rounded-full text-sm font-medium 
                      transition-all duration-200 ${
    viewMode === 'table'
      ? 'bg-primary text-primary-content shadow-sm'
      : 'text-base-content/60 hover:text-base-content hover:bg-base-200/50'
    }`}
                  >
                    <TableCellsIcon className="w-4 h-4 mr-1.5" />
                    {chatI18n.TABLE[lang]}
                  </button>
                </div>
              </div>
            </div>

            {/* Query Form */}
            {workflowSettings?.schema?.fields && (
              <Suspense 
                fallback={
                  <div className="h-16 animate-pulse bg-base-200/50 rounded-xl backdrop-blur-sm" />
                }
              >
                <div className="bg-base-100/50 backdrop-blur-sm border border-base-200 
                  rounded-xl shadow-sm">
                  <DynamicQueryForm
                    fields={workflowSettings.schema.fields}
                    values={filters}
                    totalCount={workflowData.count || 0}
                    onChange={setFilters}
                    onSearch={handleQuerySubmit}
                    onToggle={setIsPanelOpen}
                  />
                </div>
              </Suspense>
            )}
          </div>
        </div>
      </div>

      <div className={`transition-all duration-300 ease-in-out mx-auto
        ${isPanelOpen ? 'lg:mr-[666px]' : 'lg:mr-0'}`}>
        <div className="max-w-7xl mx-auto px-4 md:px-8 py-6">
          {isLoading && !workflowData?.length ? (
            <LoadingGrid count={6} />
          ) : (
            <>
              {workflowData?.data?.length === 0 ? (
                <EmptyState />
              ) : (
                <>
                  {viewMode === 'grid' ? (
                    // Grid View
                    <>
                      <Masonry
                        breakpointCols={breakpointColumns}
                        className="flex -ml-4 w-auto"
                        columnClassName="pl-4 bg-clip-padding"
                      >
                        {workflowData?.data?.map((item, index) => {
                          return (
                            <div key={index} className="mb-4">
                              <PaperCard
                                paper={item}
                                isSelected={selectedAssets.some(p => p.id === item.id)}
                                onClick={() => toggleAssetSelect(item)}
                              />
                            </div>
                          );
                        })}
                      </Masonry>
                      {hasMore && <div ref={observerTarget} className="h-10 mt-4" />}
                    </>
                  ) : (
                    // Table View
                    <div className="flex flex-col">
                      {/* Add Download Button above the grid */}
                      <div className="mb-4">
                        <button
                          onClick={handleDownload}
                          className="btn btn-outline btn-sm gap-2"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg>
                          {lang === 'en' ? 'Download Sheet' : '下载表格'}
                        </button>
                      </div>
                  
                      <div className="ag-theme-alpine flex-grow" style={{ height: 'calc(100vh - 350px)' }}>
                        <AgGridReact
                          ref={gridRef}
                          rowHeight={workflowId === 'drug-compete' ? 140 : 200}
                          rowData={workflowData?.data || []}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          rowSelection={{ mode: 'multiRow', headerCheckbox: false, enableSelectionWithoutKeys: true, enableClickSelection: true }}
                          selectionColumnDef={{
                            suppressHeaderMenuButton: false,
                            suppressHeaderFilterButton: true,
                            pinned: 'left',
                          }}
                          suppressRowClickSelection={false}
                          rowMultiSelectWithClick={true}
                          onRowSelected={(event) => {
                            console.log(event)
                            const selectedNodes = event.api.getSelectedNodes();
                            const selectedData = selectedNodes.map(node => node.data);
                            const allNodes = [];
                            event.api.forEachNode(node => allNodes.push(node));

                            // If this is a deselection (unselect all), allow it to proceed
                            if (selectedData.length < selectedAssets.length) {
                              setSelectedAssets(selectedData);
                              return;
                            }

                            // If trying to select more than limit
                            if (selectedData.length > MAX_SELECTED_ASSETS) {
                              // Find selected nodes in their original order
                              const orderedSelectedNodes = allNodes.filter(node => node.isSelected());
                              
                              // Keep only the first N nodes where N is the limit
                              const nodesToKeep = orderedSelectedNodes.slice(0, MAX_SELECTED_ASSETS);
                              
                              // Deselect all nodes first
                              event.api.deselectAll();
                              
                              // Select only the first N nodes
                              nodesToKeep.forEach(node => node.setSelected(true));
                              
                              showAlert('error', `You can only select up to ${MAX_SELECTED_ASSETS}`);
                              
                              // Update selectedAssets with the kept nodes
                              setSelectedAssets(nodesToKeep.map(node => node.data));
                              return;
                            }
                            
                            setSelectedAssets(selectedData);
                          }}
                          getRowId={(params) => params.data.id}
                          onGridReady={(params) => {
                            gridApiRef.current = params.api;
                            
                            // Initial selection sync
                            if (selectedAssets.length > 0) {
                              params.api.forEachNode(node => {
                                const shouldBeSelected = selectedAssets.some(asset => asset.id === node.data.id);
                                node.setSelected(shouldBeSelected);
                              });
                            }
                          }}
                          animateRows={true}
                          enableCellTextSelection={true}
                          suppressNoRowsOverlay={true}
                          suppressColumnMoveAnimation={true}
                          maintainColumnOrder={true}
                          onSortChanged={() => {
                            // Refresh index column after sorting
                            refreshIndexColumn();
                          }}
                          onFilterChanged={() => {
                            // Refresh index column after filtering
                            refreshIndexColumn();
                          }}
                        />
                      </div>

                      {/* Table footer with load more button */}
                      <div className="mt-4 flex justify-between items-center gap-4 py-4 border-t border-base-200">
                        <div className="text-sm text-base-content/70">
                          {chatI18n.SHOWING_PAPERS[lang]} {workflowData?.data?.length || 0} {chatI18n.ITEMS[lang]} ｜ {chatI18n.TOTAL_RESULTS_COUNT[lang].replace('{count}', workflowData?.count)}
                        </div>
                        
                        <div className="flex items-center gap-4">
                          {isLoading ? (
                            <div className="flex items-center gap-2 text-base-content/70">
                              <div className="loading loading-spinner loading-sm"></div>
                              <span>{chatI18n.LOADING_MORE[lang]}</span>
                            </div>
                          ) : hasMore ? (
                            <button
                              onClick={loadMoreData}
                              className="btn-primary btn gap-2"
                            >
                              {chatI18n.LOAD_MORE[lang]}
                            </button>
                          ) : (
                            <div className="text-base-content/70">
                              {chatI18n.END_OF_DATA[lang]} • {workflowData?.data?.length || 0} {chatI18n.ITEMS[lang]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* Chat Panel */}
      <ChatPanel 
        enableUpload={false}
        isOpen={isPanelOpen}
        onToggle={setIsPanelOpen}
        suggestions={workflowSettings?.schema?.followup_questions}
        initialConversations={threadData?.initialData?.results || []}
        assetId={workflowId}
        assetType="conference"
        assetTitle={workflowSettings.title}
        threadId={activeThreadId}
        onThreadCreated={handleThreadCreated}
        selectedItems={selectedAssets}
        onItemRemove={toggleAssetSelect}
        itemType="conference"
      />
    </div>
  );
}

export async function conferenceDetailLoader(location) {
  console.log('conferenceDetailLoader');
  const { conferenceId: workflowId, threadId } = location.params;
  
  // Get initial query values
  let initialQueryValues = {};
  const id = new URLSearchParams(new URL(location.request.url).search).get('id');

  const queryValuesForSearch = formatQueryValues(initialQueryValues);
  // Fetch data using the format initial query values
  const [ workflowSettings, workflowData ] = await Promise.all([
    getConferenceSettings(workflowId),
    getConferenceDetail(workflowId, queryValuesForSearch)
  ]);

  if (threadId) {
    const threadTasks = await getThreadTasks(threadId);
    const needFetchLatest =
      threadTasks?.results?.at(0) &&
      ![ 'complete', 'failed' ].includes(threadTasks?.results?.at(0)?.task_status);

    let runningTask = null;
    if (needFetchLatest) {
      runningTask = threadTasks.results.splice(0, 1)?.at(0);
    }
    threadTasks.results.reverse();

    return {
      workflowSettings,
      workflowData,
      initialQueryValues,
      queryValuesForSearch,
      threadData: {
        needFetchLatest,
        initialData: threadTasks,
        runningTask,
        threadId,
      },
    };
  }
  console.log('conference loader data', { 
    workflowSettings,
    workflowData,
    initialQueryValues,
    queryValuesForSearch,
    threadData: {
      needFetchLatest: false,
      initialData: { results: [] },
      runningTask: null,
      threadId: null,
    },
  })
  return { 
    workflowSettings,
    workflowData,
    initialQueryValues,
    queryValuesForSearch,
    threadData: {
      needFetchLatest: false,
      initialData: { results: [] },
      runningTask: null,
      threadId: null,
    },
  };
} 
