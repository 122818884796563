import { useState } from 'react';

import DOMPurify from 'dompurify';
import MarkdownIt from 'markdown-it';
import MILA from 'markdown-it-link-attributes';
import MarkdownItKatex from 'markdown-it-texmath';
import ReactDOMServer from 'react-dom/server';
// create a custom link component with circle avatar
const CustomLink = ({ children, url }) => {
  const [ isHovered, setIsHovered ] = useState(false);

  // Extract number, removing brackets if present
  const number = children?.toString().replace(/[[\]]/g, '');

  return (
    <span
      className="inline-flex items-center relative group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          e.preventDefault();
          window.dispatchEvent(
            new CustomEvent('reference-click', { detail: { number } })
          );
        }}
        className="
          inline-flex items-center justify-center
          text-primary-content
          hover:text-primary-content
          align-super
          text-xs
          transition-colors duration-200
          cursor-pointer
          relative
          px-[0.2em]
          -top-[0.4em]
          group
        "
      >
        <span
          className="
          relative
          after:content-['']
          after:absolute
          after:left-0
          after:right-0
          after:bottom-0
          after:h-[1px]
          after:bg-primary/30
          after:transition-all
          after:duration-200
          group-hover:after:bg-primary/60
          group-hover:after:h-[1.5px]
        "
        >
          {number}
        </span>
      </a>

      {/* Hover tooltip */}
      {isHovered && (
        <div
          className="
          absolute bottom-full left-1/2 -translate-x-1/2 mb-2
          w-[280px]
          p-3
          bg-base-100/95
          backdrop-blur-sm
          border border-base-200
          rounded-lg
          shadow-lg
          text-sm
          z-50
        "
        >
          <div className="flex flex-col gap-2">
            <span className="font-medium text-base-content">
              Reference {number}
            </span>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="
                text-xs
                text-primary-content
                
                transition-colors
                duration-200
                line-clamp-2
                break-all
              "
            >
              {url}
            </a>
          </div>

          {/* Arrow */}
          <div
            className="
            absolute -bottom-[6px] left-1/2 -translate-x-1/2
            w-3 h-3
            rotate-45
            bg-base-100/95
            backdrop-blur-sm
            border-r border-b border-base-200
          "
          />
        </div>
      )}
    </span>
  );
};

const md = new MarkdownIt();
md.use(MarkdownItKatex, {
  delimiters: [ 'dollars', 'brackets' ],
});
md.use(MILA, {
  attrs: {
    target: '_blank',
  },
});

// TODO: need optimization! better looking and UX
// to customize the link ([1] => beautiful avatar like)
md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  const href = tokens[idx].attrGet('href');
  let content = '';

  // Locate the content token between link_open and link_close
  for (let i = idx + 1; i < tokens.length; i++) {
    if (tokens[i].type === 'text') {
      content = tokens[i].content; // Capture the link body content
      tokens[i].content = ''; // Example modification
      break;
    }

    if (tokens[i].type === 'link_close') {
      break; // Stop processing once link_close is found
    }
  }

  // console.log('Link content:', content);

  // Render custom React component
  const customLink = ReactDOMServer.renderToString(
    <CustomLink href={href}>{content}</CustomLink>
  );

  // Return the custom link component as a string
  return customLink;
};

md.renderer.rules.link_close = function (tokens, idx, options, env, self) {
  // Since the custom link includes closing tags, return an empty string
  return '';
};

function processMarkdownToCleanHTML(text) {
  if (!text) return null;
  // Ensure HTTPS in Markdown URLs and handle line breaks for Markdown rendering
  const processedText = text
    .replace(/\[.*?\]\((\S+?)\)/g, (match, url) => {
      // Prepend 'https://' if URL lacks protocol
      if (!/^https?:\/\//i.test(url)) {
        return match.replace(url, `https://${url}`);
      }
      return match;
    })
    .replace(/\n/g, '  \n'); // Handle Markdown line breaks

  // Sanitize and render the Markdown text to HTML
  // Placeholder for DOMPurify, assuming it's available in your environment
  const cleanHTML = DOMPurify.sanitize(md.render(processedText), {
    ADD_ATTR: [ 'target' ], // Specify any additional attributes you want to allow through DOMPurify
  });

  return cleanHTML;
}

function extractThinkTag(text = '') {
  let think = '';
  let mainResponse = '';
  const startIndex = text.indexOf('<think>');
  const endIndex = text.indexOf('</think>');
  if (startIndex === 0) {
    if (endIndex >= 0) {
      think = text.slice(7, endIndex);
      mainResponse = text.slice(endIndex + 8);
    } else {
      think = text.slice(7);
    }
  } else {
    mainResponse = text;
  }
  return { think, mainResponse }
}

export { processMarkdownToCleanHTML, extractThinkTag };
