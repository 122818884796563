// export a component receives item and index as props
import React, { useCallback, useEffect, useState } from 'react';

import {
  ClipboardIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  LightBulbIcon,
  PlusIcon,
  ShareIcon,
} from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';

import { updateTaskComment } from '../../api';
import ThoughtNode from '../../Common/thought';
import { useAlert } from '../../utils/context/alert';
import { useLanguage } from '../../utils/context/lang';
import { useUser } from '../../utils/context/user';
import { processMarkdownToCleanHTML } from '../../utils/markdown-to-html';
import { agents, BIOMEDICAL_TYPE, CLINICAL_TYPE } from '../const/agent';
import { i18n } from '../i18n/common';

import ChatSection from './ChatSection';
import FileThumbnail from './fileThumbnail';
import DisplayFormattedText from './markdown';
import { PaperTable } from './paper/mainTable';
import { PiTable } from './pi/mainTable';

export function OneConversation({ item, onTriggerNewQuestion, onReferenceClick }) {
  // console.log('jijijijijijijiji', item)
  const { lang } = useLanguage();
  const { user } = useUser();
  const [ isThumbUp, setIsThumbUp ] = useState(item.task_comment == 1);
  const [ isThumbDown, setIsThumbDown ] = useState(item.task_comment == -1);
  const [ thought, setThought ] = useState({});
  const showAlert = useAlert();
  useEffect(() => {
    // parse thoght when thought change
    if (item.thought) {
      setThought(JSON.parse(item.thought));
    }
  }, [ item.thought ]);
  
  const { mutate: mutateThumb } = useMutation(
    (thumb) => updateTaskComment(item.id ?? item.task_id, thumb),
    {
      onSuccess: (data, variables) => {
        // console.log(data);
        // console.log(variables);
        if (data.status === 200) {
          if (variables == 1) {
            setIsThumbUp(true);
            setIsThumbDown(false);
          } else if (variables == 0) {
            setIsThumbUp(false);
            setIsThumbDown(false);
          } else if (variables == -1) {
            setIsThumbUp(false);
            setIsThumbDown(true);
          }
        }
      },
    }
  );

  const handleReferenceClick = useCallback((ref) => {
    onReferenceClick({
      sources: thought.source,
      referenceIndex: +ref,
    });
  }, [ thought.source ]);

  let tableSection;
  if (item.agent === CLINICAL_TYPE) {
    tableSection = <PiTable piData={item} />;
  } else if (item.agent === BIOMEDICAL_TYPE && item.result_json) {
    tableSection = <PaperTable paperData={item.result_json} />;
  } else {
    tableSection = <div></div>;
  }

  const copyContent = (content) => {
    console.log('Copying content to clipboard', typeof content);
    navigator.clipboard.writeText(content).then(
      () => {
        console.log('HTML content copied to clipboard');
        showAlert('success', i18n.SUCCESS_COPY[lang]);
      },
      (err) => {
        console.error('Could not copy text: ', err);
        showAlert('error', i18n.FAILED_COPY[lang]);
      }
    );

    //  The below code is good, but it cannot be pasted to text-only env like wechat.
    // const html = processMarkdownToCleanHTML(content);
    // const blob = new Blob([ html ], { type: 'text/html' });
    // const data = [ new ClipboardItem({ 'text/html': blob }) ];
    // navigator.clipboard.write(data).then(() => {
    //   console.log('HTML content copied to clipboard');
    //   showAlert('success', i18n.SUCCESS_COPY[lang])
    // }, (err) => {
    //   console.error('Could not copy text: ', err);
    //   showAlert('error', i18n.FAILED_COPY[lang])
    // });
  };

  const handleViewAllReferences = (referenceIndex) => {
    onReferenceClick({
      sources: thought.source,
      referenceIndex: referenceIndex,
    });
  };

  return (
    <div className="overflow-x-hidden">
      <ChatSection
        imageUrl={`https://placehold.co/50x50?text=${
          user?.username?.at(0)?.toUpperCase() || 'user'
        }`}
        role="you"
      >
        <div className="text-primary-content mb-4 whitespace-pre-wrap">
          <div className="flex flex-wrap mb-2">
            {item?.upload_files &&
              item?.upload_files.length > 0 &&
              item.upload_files.map((upload, index) => (
                <FileThumbnail
                  key={upload.id || upload.name}
                  upload={upload}
                  allowDelete={false}
                />
              ))}
          </div>
          {item?.question}
        </div>
      </ChatSection>

      {thought?.source && thought?.source?.length > 0 && (
        <div className="relative flex text-base mx-auto mb-4 gap-3 group max-w-5xl">
          <div className="relative flex w-full flex-col text-primary-content">
            <div className="font-semibold select-none">{i18n.REFERENCE[lang]}</div>
            <div className="relative flex-col gap-1">
              <div className="relative md:grid md:grid-cols-4 gap-2 min-h-24">
                {/* Show first 3 references */}
                {thought.source?.slice(0, 3).map((source, index) => (
                  <div
                    key={index}
                    className="flex relative flex-col px-2 py-2 my-2 bg-base-300 rounded-sm justify-between cursor-pointer"
                    onClick={
                      ()=>handleViewAllReferences(index)
                      // () => window.open(source.url, '_blank')
                    }
                  >
                    <p className="line-clamp-3 text-left text-xs text-primary-color mb-2">
                      {source.title}
                    </p>
                    <p className="line-clamp-1 text-left text-xs font-bold text-primary-color">
                      {source.site_name}
                    </p>
                  </div>
                ))}

                {/* Show View More card if more than 3 references */}
                {thought.source?.length > 3 && (
                  <div
                    className="flex relative flex-col px-2 py-2 my-2 bg-base-300 hover:bg-base-200 rounded-sm justify-center items-center cursor-pointer transition-colors duration-200"
                    onClick={handleViewAllReferences}
                  >
                    <div className="text-center">
                      <p className="text-primary-color font-medium mb-1">View All</p>
                      <p className="text-xs text-primary-color/70">
                        +{thought.source.length - 3} more references
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <ChatSection
        // role={
        //   agents.filter((agent) => agent.category === item.agent)?.at(0)[
        //     `role_${lang}`
        //   ]
        // }
      >
        <div className="mb-4">
          {(item.thought || item.progress_hint) && (
            // <div
            //   tabIndex={0}
            //   className="collapse rounded-none border-l-8 my-2 border-[#05aad5]"
            // >
            //   <div className="collapse-title text-xl font-medium flex items-center">
            //     {i18n.THOUGHT[lang]}
            //     <LightBulbIcon className="w-6 h-6 ml-1" />
            //   </div>
            //   <div className="collapse-content">
            //     {
            //       <DisplayFormattedText
            //         className="text-white"
            //         text={item?.thought ?? item?.progress_hint}
            //       />
            //     }
            //   </div>
            // </div>
            // <ThoughtNode thought={JSON.parse(item.thought || item.progress_hint)} />
            <ThoughtNode thought={thought} />
          )}
          <p className="">
            {/* NOTE: we use useCallback to solve the problem of too many repaint */}
            {<DisplayFormattedText 
              text={item?.response} 
              onReferenceClick={handleReferenceClick}
            /> || (
              <span className="text-red-300">
                something went wrong, please try again later or{' '}
                <a href="/contact" target="_blank">
                  contact us
                </a>
              </span>
            )}
          </p>
          {/* render item.followup_questions */}
          {item?.followup_questions?.length > 0 && (
            <div className="mt-4">
              <div className="text-lg font-semibold">{i18n.FOLLOWUP_QUESTIONS[lang]}</div>
              <ul className="">
                {item.followup_questions.map((followup_question, index) => (
                  <li key={index} className="relative min-w-0 max-w-full flex justify-between items-center my-2 border-b border-gray-900/5 cursor-pointer"
                    onClick={()=>{
                      onTriggerNewQuestion(followup_question);
                    }}>
                    <div className="flex-1 focus:outline-none mr-6 cursor-pointer hover:underline">
                      <span
                        aria-hidden="true"
                        className="absolute inset-0"
                      />
                      <p className="text-md leading-8 font-medium text-primary-color line-clamp-2 max-w-full hover:underline cursor-pointer">
                        {followup_question}
                      </p>
                    </div>
                    <PlusIcon className="w-5 h-5 text-primary-color" />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* TODO: @yichen, need remove this result_json, and need to be the same as above followup_question to be structure consist */}
          {item?.result_json?.followup_questions?.length > 0 && (
            <div className="mt-4">
              <div className="text-lg font-semibold">{i18n.FOLLOWUP_QUESTIONS[lang]}</div>
              <ul className="">
                {item.result_json.followup_questions.map((followup_question, index) => (
                  <li key={index} className="relative min-w-0 max-w-full flex justify-between items-center my-2 border-b border-gray-900/5 cursor-pointer hover:underline"
                    onClick={()=>{
                      onTriggerNewQuestion(followup_question);
                    }}>
                    <div href="#" className="flex-1 focus:outline-none mr-6">
                      <span
                        aria-hidden="true"
                        className="absolute inset-0"
                      />
                      <p className="text-md leading-8 font-medium text-primary-color line-clamp-2 max-w-full hover:underline cursor-pointer">
                        {followup_question}
                      </p>
                    </div>
                    <PlusIcon className="w-5 h-5 text-primary-color" />
                  </li>
                
                ))}
              </ul>
            </div>
          )}
          {item.result_json && tableSection}
          {item.result_file && (
            <div className="">
              <a
                href={item.result_file.download}
                target="_blank"
                className="text-blue-500"
                rel="noreferrer"
              >
                click to download
              </a>
            </div>
          )}
        </div>
        <div className="chat-footer opacity-50">
          <div className="flex mt-2 justify-between">
            {/* <div className="flex">
              <ShareIcon
                title="Share"
                onClick={() => alert('In development')}
                className="h-5 w-5 cursor-pointer text-neutral-content hover:text-secondary-content"
                aria-hidden="true"
              />
            </div> */}
            <div className="flex">
              <HandThumbUpIcon
                title="Like"
                onClick={() => mutateThumb(isThumbUp ? 0 : 1)}
                className={`h-5 w-5 cursor-pointer ${
                  isThumbUp
                    ? 'text-secondary-content'
                    : 'text-neutral-content hover:text-secondary-content'
                }`}
                aria-hidden="true"
              />
              <HandThumbDownIcon
                title="Report"
                onClick={() => mutateThumb(isThumbDown ? 0 : -1)}
                className={`ml-3 h-5 w-5 cursor-pointer ${
                  isThumbDown
                    ? 'text-secondary-content'
                    : 'text-neutral-content hover:text-secondary-content'
                }`}
                aria-hidden="true"
              />
              <ClipboardIcon
                title="Copy"
                onClick={() => copyContent(item?.response)}
                className={`ml-3 h-5 w-5 cursor-pointer text-neutral-content hover:text-secondary-content'
              }`}
                aria-hidden="true"
              />
            </div>

          </div>
        </div>
      </ChatSection>
    </div>
  );
}
